import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import CalendarComp from "../components/CalendarComp";
import TypeOfDelivery from "../components/TypeOfDelivery";
import axios from "axios";
import { deliveryOrder } from "../components/TransportAPI"; // Adjust path as needed

const ServiceReturnPage = () => {
  const { bookingId } = useParams(); // Extract the bookingId from the URL
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    bookingId: bookingId || "",
    subscriptionId: "",
    orderId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    serialNumber: "",
    address: "",
    postalCode: "",
    city: "",
    wandaID: "",
    deliveryType: "nydalen", // Default to Nydalen pickup
    returnDate: "",
    selectedTimeslot: "",
  });

  // Fetch the booking data from the server based on bookingId
  useEffect(() => {
    const fetchBookingData = async () => {
      try {
        const response = await axios.get(`/api/bookings/${bookingId}`);
        const bookingData = response.data;

        setData((prevData) => ({
          ...prevData,
          firstName: bookingData.firstName,
          lastName: bookingData.lastName,
          email: bookingData.email,
          serialNumber: bookingData.serialNumber,
          address: bookingData.addressStreet,
          postalCode: bookingData.addressPostalCode,
          city: bookingData.addressCity,
          orderId: bookingData.orderId,
          subscriptionId: bookingData.subscriptionId,
          phone: bookingData.number,
        }));
      } catch (error) {
        console.error("Error fetching booking data:", error);
      }
    };

    if (bookingId) {
      fetchBookingData();
    }
  }, [bookingId]);

  // Handle input changes
  const handleInputChange = (field, value) => {
    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Code that updates the count on bike movements from service in the dailymovementsummary collection
  const updateDailyMovementSummary = async () => {
    const requestBody = {
      bookingDate: data.returnDate, // Use returnDate for the summary update
      deliveryType: data.deliveryType, // "transport" or "nydalen"
      movementType: "delivery",
      activityType: "service",
    };

    try {
      await axios.post("/api/dailyMovements/update", requestBody);
      console.log("Daily Movement Summary Updated Successfully");
    } catch (error) {
      console.error("Error updating daily movement summary:", error);
    }
  };

  // Function to handle submission of booking
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // If they chose transport, call deliveryOrder to create the Opter order
      if (data.deliveryType === "transport") {
        await deliveryOrder(data);
      }

      // If the delivery type is 'transport ', make a one-time payment
      if (data.deliveryType === "transport") {
        const price = data?.selectedTimeslot?.price;
        const paymentData = {
          amount: price,
          order_id: data.orderId,
          message: `Kostnad for transport den ${data.returnDate} i forbindelse med gjennomført service`,
          products: [
            {
              product: "Transport",
              amount: price,
              tax_percent: 25,
              quantity: 1,
            },
          ],
        };

        // Trigger the one-time payment API
        await axios.post("/api/circuly/one-time-payment", paymentData);
      }

      //Add returnTime from selectedtimeslot in calendarComp
      const returnTime =
        data.selectedTimeslot &&
        data.selectedTimeslot.from &&
        data.selectedTimeslot.to
          ? `${data.selectedTimeslot.from}-${data.selectedTimeslot.to}`
          : "";

      // Update the booking with return details and send SMS confirmation
      await axios.put(`/api/bookings/${data.bookingId}/return-details`, {
        returnDate: data.returnDate,
        returnTime,
        deliveryTypeToCustomer: data.deliveryType,
        number: data.phone, // Use number instead of phone
      });

      // Update daily movement summary
      await updateDailyMovementSummary();

      // Navigate to confirmation page on success
      navigate("/service-booking/return-confirmation", {
        state: { bookingData: data },
      });
    } catch (error) {
      console.error("Error submitting data:", error);
      setErrorMsg(
        "Vi klarte ikke å registrere din bestilling av utlevering. Kontakt oss på hei@joule så hjelper vi deg"
      );
    }
    setIsLoading(false);
  };

  // Require both returnDate and selectedTimeslot for all serviceReturns.)
  const isSubmitDisabled = !(data.returnDate && data.selectedTimeslot);

  const buttonClass = `buttonClass w-38 tracking-wide font-bold rounded-lg inline-flex items-center justify-center py-2 px-6 ${
    isSubmitDisabled
      ? "bg-gray-200 text-gray-400 border-gray-200 cursor-not-allowed"
      : "bg-white text-black shadow-black shadow-md hover:shadow-black hover:shadow-lg hover:scale-105"
  }`;

  return (
    <div>
      <TopBar />
      <div className="w-full h-full min-h-full flex flex-col justify-center px-4 lg:px-8 z-100 mf:h-screen">
        <h2 className="mt-6 mb-4 text-center text-lg font-century-gothic text-jouletext">
          Velg Transportmetode
        </h2>

        <TypeOfDelivery
          data={data}
          setData={setData}
          deliveryTypeVarName="deliveryType"
          sourcePage="ServiceReturnPage"
        />

        <h2 className="mt-6 mb-4 text-center text-lg font-century-gothic text-jouletext">
          Velg Dato for Utlevering
        </h2>

        <CalendarComp
          data={data}
          setData={setData}
          sourcePage="ServiceReturnPage"
        />

        <div className="flex flex-col items-center my-2">
          {data.deliveryType === "transport" && (
            <>
              <h3 className="my-2 text-center text-sm font-century-gothic text-jouletext">
                Adresse
              </h3>
              <input
                type="text"
                placeholder="Street Address"
                value={data.address}
                onChange={(e) => handleInputChange("address", e.target.value)}
                className="my-1 text-center py-2 w-full sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-600"
              />
              <h3 className="my-2 text-center text-sm font-century-gothic text-jouletext">
                Sted
              </h3>
              <input
                type="text"
                placeholder="City"
                value={data.city}
                onChange={(e) => handleInputChange("city", e.target.value)}
                className="my-1 text-center py-2 w-full sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-600"
              />
            </>
          )}
        </div>

        {errorMsg && <div className="text-red-400 text-center">{errorMsg}</div>}
        <div className="flex justify-center my-4 pb-8">
          <button
            onClick={handleSubmit}
            className={`${buttonClass} flex justify-center items-center`}
            disabled={isLoading || isSubmitDisabled}
          >
            {isLoading ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900 mr-2"></div>
                Laster...
              </>
            ) : (
              "Bekreft Utlevering"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceReturnPage;
