import React from "react";
import { useLocation } from "react-router-dom";
import ConfirmationPageComp from "../components/ConfirmationPageComp";

const ServiceBookingConfirmationPage = () => {
  const location = useLocation();
  const bookingData = location.state?.bookingData || {};

  return <ConfirmationPageComp pageType="serviceBooking" data={bookingData} />;
};

export default ServiceBookingConfirmationPage;
