import React from "react";
import { useLocation } from "react-router-dom";
import ConfirmationPageComp from "../components/ConfirmationPageComp";

const ReturnConfirmationPage = () => {
  const location = useLocation();
  const returnData = location.state?.returnData || {};

  return <ConfirmationPageComp pageType="return" data={returnData} />;
};

export default ReturnConfirmationPage;
