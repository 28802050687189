// src/components/ConfirmationPageComp.jsx

import React from "react";
import { format } from "date-fns";
import nbLocale from "date-fns/locale/nb";
import TopBar from "./TopBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

// Helper function to safely format a date string or return "Ikke tilgjengelig"
const formatDateOrFallback = (dateString) => {
  if (!dateString) return "Ikke tilgjengelig";
  try {
    return format(new Date(dateString), "PP", { locale: nbLocale });
  } catch {
    return "Ikke tilgjengelig";
  }
};

const ConfirmationPageComp = ({ pageType, data }) => {
  // Determine if the user selected transport or nydalen
  const isTransport = data?.deliveryType === "transport";

  //------------------------------------------------------------
  // 1) Decide main heading & date label
  //------------------------------------------------------------
  let mainHeading = "";
  let dateLabel = "";
  let actualDate = "";

  switch (pageType) {
    case "serviceBooking":
      mainHeading = "Din booking er bekreftet!";
      dateLabel = "Innleveringsdato";
      actualDate = data?.bookingDate;
      break;

    case "serviceReturn":
      mainHeading = "Din utlevering er bekreftet!";
      dateLabel = "Utleveringsdato";
      actualDate = data?.returnDate;
      break;

    case "return":
      mainHeading = "Din tilbakelevering er bekreftet!";
      dateLabel = "Tilbakeleveringsdato";
      actualDate = data?.deliveryDate;
      break;

    case "delivery":
      mainHeading = "Din utlevering er bekreftet!";
      dateLabel = "Utleveringsdato";
      actualDate = data?.deliveryDate;
      break;

    default:
      mainHeading = "Bekreftelse";
      dateLabel = "Dato";
      actualDate = "";
  }

  const formattedDate = formatDateOrFallback(actualDate);

  //------------------------------------------------------------
  // 2) Decide location text
  //------------------------------------------------------------
  let locationText = "";
  switch (pageType) {
    case "serviceBooking":
      locationText = isTransport
        ? "Henting av sykkelen vil bli arrangert av vår transportpartner Hurtig-Gutta."
        : "Gjerdrums vei 11, Nydalen";
      break;

    case "serviceReturn":
      locationText = isTransport
        ? "Levering av sykkelen vil bli arrangert av vår transportpartner Hurtig-Gutta."
        : "Gjerdrums vei 11, Nydalen";
      break;

    case "return":
      locationText = isTransport
        ? "Henting av sykkelen vil bli arrangert av vår transportpartner Hurtig-Gutta."
        : "Gjerdrums vei 11, Nydalen";
      break;

    case "delivery":
      locationText = isTransport
        ? "Levering av sykkelen vil bli arrangert av vår transportpartner Hurtig-Gutta."
        : "Gjerdrums vei 11, Nydalen";
      break;

    default:
      locationText = "N/A";
  }

  //------------------------------------------------------------
  // 3) Decide “what happens next” text
  //------------------------------------------------------------
  let nextStepsText = "";
  switch (pageType) {
    case "serviceBooking":
      nextStepsText = isTransport
        ? "Vår transportleverandør vil sende deg en bekreftelse på henting. Servicen skal normalt ikke ta mer enn 2 arbeidsdager. Når den er ferdig, mottar du en SMS for å avtale levering/henting."
        : "Vi ser frem til å ta vare på sykkelen din. Servicen skal normalt ikke ta mer enn 2 arbeidsdager. Du mottar en SMS når den er ferdig, slik at du kan avtale henting.";
      break;

    case "serviceReturn":
      nextStepsText = isTransport
        ? "Vi ser frem til å returnere sykkelen din i topp stand. Vår transportleverandør vil sende deg en bekreftelse på levering"
        : "Vi ser frem til å returnere sykkelen din i topp stand. Du kan hente den i våre åpningstider mellom 10-17.";
      break;

    case "return":
      nextStepsText = isTransport
        ? "Vår transportpartner, Wanda, vil hente sykkelen hos deg på den avtalte datoen. Du får en påminnelse dagen før henting. Takk for at du valgte Joule!"
        : "Du kan levere sykkelen til vårt servicesenter i Nydalen på den avtalte datoen. Våre åpningstider er 10:00 - 17:00. Takk for at du syklet med Joule!";
      break;

    case "delivery":
      nextStepsText = isTransport
        ? "Vår transportleverandør vil levere sykkelen hjem til deg på den avtalte datoen. Du vil få en påminnelse dagen før."
        : "Du kan hente sykkelen ved vårt servicesenter i Gjerdrums vei 11 på den avtalte datoen (10:00 - 17:00).";
      break;

    default:
      nextStepsText = "Ta kontakt med oss hvis du har spørsmål.";
  }

  //------------------------------------------------------------
  // 4) Optional: “Type” or “Merknader” (notes)
  //------------------------------------------------------------
  // Some pages show "Type: ...", or we can display data.type if it exists
  const hasType = !!data?.type; // e.g. "Service", "Retur", etc.
  const hasNote = !!data?.note && data.note.trim() !== "";

  //------------------------------------------------------------
  // 5) Timeslot (always "Tidsvindu" and use data.selectedTimeslot)
  //------------------------------------------------------------
  let timeslotLabel = "Tidsvindu";
  let timeslotValue = "Ikke spesifisert";

  if (data?.selectedTimeslot?.from && data.selectedTimeslot?.to) {
    timeslotValue = `${data.selectedTimeslot.from} - ${data.selectedTimeslot.to}`;
  }

  //------------------------------------------------------------
  // 6) Render
  //------------------------------------------------------------
  return (
    <main className="flex flex-col min-h-screen py-4 bg-joule">
      <TopBar />
      <section className="flex-grow flex flex-col items-center justify-center px-4 sm:px-8 lg:px-8">
        <article className="bg-white p-6 rounded-lg shadow-lg text-center w-full max-w-lg">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-joule"
            size="3x"
          />

          <h2 className="text-2xl font-century-gothic text-jouledark mt-4 mb-6">
            {mainHeading}
          </h2>

          {/* Use a definition list for label-value pairs */}
          <dl className="text-left font-century-gothic mb-4 space-y-3">
            {hasType && (
              <div>
                <dt className="font-bold inline">Type:</dt>{" "}
                <dd className="inline">{data.type}</dd>
              </div>
            )}

            <div>
              <dt className="font-bold inline">{dateLabel}:</dt>{" "}
              <dd className="inline">{formattedDate}</dd>
            </div>

            <div>
              <dt className="font-bold inline">Lokasjon:</dt>{" "}
              <dd className="inline">{locationText}</dd>
            </div>

            <div>
              <dt className="font-bold inline">{timeslotLabel}:</dt>{" "}
              <dd className="inline">{timeslotValue}</dd>
            </div>
          </dl>

          {/* “Dine merknader” if present */}
          {hasNote && (
            <div className="text-left mt-6 bg-gray-100 p-4 rounded-lg">
              <h3 className="font-bold text-gray-700">Dine merknader:</h3>
              <p className="mt-2 text-gray-600">{data.note}</p>
            </div>
          )}

          {/* Next steps */}
          <div className="mt-8">
            <h3 className="text-xl font-century-gothic text-jouledark mb-4">
              Hva skjer videre?
            </h3>
            <p className="text-gray-600">{nextStepsText}</p>
            <p className="mt-6 text-gray-600">
              Trenger du å endre bookingen? Send oss en e-post på{" "}
              <a
                href="mailto:hei@joule.no"
                className="text-jouledark underline hover:text-gray-500"
              >
                hei@joule.no
              </a>
              .
            </p>
          </div>
        </article>
      </section>
    </main>
  );
};

export default ConfirmationPageComp;
