import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import CalendarComp from "../components/CalendarComp";
import TypeOfDelivery from "../components/TypeOfDelivery";
import TypeOfBooking from "../components/TypeOfBooking";
import axios from "axios";
import { pickupOrder } from "../components/TransportAPI"; // Adjust path as needed

const ServiceBookingPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    type: "Service", // Default to Service
    subscriptionId: state?.subscriptionId || "",
    orderId: state?.subscriptionDetails?.order_id || "",
    firstName:
      state?.subscriptionDetails?.customer?.address?.billing?.first_name || "",
    lastName:
      state?.subscriptionDetails?.customer?.address?.billing?.last_name || "",
    email: state?.subscriptionDetails?.customer?.email || "",
    phone: state?.subscriptionDetails?.customer?.phone || "",
    serialNumber: state?.subscriptionDetails?.serial_number || "",
    address:
      state?.subscriptionDetails?.customer?.address?.shipping?.street || "",
    postalCode:
      state?.subscriptionDetails?.customer?.address?.shipping?.postal_code ||
      "",
    city: state?.subscriptionDetails?.customer?.address?.shipping?.city || "",
    deliveryType: "nydalen", // Default to Nydalen pickup
    bookingDate: "",
    selectedTimeslot: "",
    note: "",
    winterTyres: false,
  });

  // Function to validate the phone number
  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\+\d{10}$/;
    if (!regex.test(phoneNumber)) {
      setPhoneError("Phone number must be in the format +47XXXXXXXX");
    } else {
      setPhoneError("");
    }
  };

  // Validate the phone number on initial load
  useEffect(() => {
    validatePhoneNumber(data.phone);
  }, [data.phone]);

  // Handle input changes for data fields
  const handleInputChange = (field, value) => {
    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Effect to update bookingdate and selectedTimeslot if the postal code changes
  useEffect(() => {
    if (data.postalCode) {
      setData((prevData) => ({
        ...prevData,
        bookingDate: "",
        selectedTimeslot: "",
      }));
    }
  }, [data.postalCode]);

  // useEffect to monitor changes to 'type' and handle winterTyres and note
  useEffect(() => {
    if (data.type === "Dekkskifte") {
      setData((prevData) => ({
        ...prevData,
        winterTyres: true,
        note: "DEKKSKIFT", // Automatically set the note for Dekkskifte
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        winterTyres: false, // Reset winterTyres when switching away from Dekkskifte
        note: "", // Clear the note when it's not Dekkskifte
      }));
    }
  }, [data.type]);

  // Code that updates the count on bike movements from service in the dailymovementsummary collection
  const updateDailyMovementSummary = async () => {
    const requestBody = {
      bookingDate: data.bookingDate,
      deliveryType: data.deliveryType, // "transport" or "nydalen"
      movementType: "pickup",
      activityType: "service",
    };

    try {
      await axios.post("/api/dailyMovements/update", requestBody);
      console.log("Daily Movement Summary Updated Successfully");
    } catch (error) {
      console.error("Error updating daily movement summary:", error);
    }
  };

  // Function to handle submission of booking
  const handleSubmit = async () => {
    setIsLoading(true);

    // 1. If the type is NOT "Dekkskifte" and the note is blank, show an error
    if (data.type !== "Dekkskifte" && !data.note.trim()) {
      setErrorMsg("Melding til våre mekanikere er påkrevd.");
      setIsLoading(false);
      return;
    }

    try {
      // If they chose transport, call pickupOrder to create the Opter order
      if (data.deliveryType === "transport") {
        await pickupOrder(data);
      }

      // If the delivery type is 'transport', make a one-time payment
      if (data.deliveryType === "transport") {
        const price = data?.selectedTimeslot?.price;
        const paymentData = {
          amount: price, //NB set price based on postal code
          order_id: data.orderId,
          message: `Kostnad for transport den ${data.bookingDate} i forbindelse med serviceavtale`,
          products: [
            {
              product: "Servicetransport",
              amount: price, //NB set price based on postal code
              tax_percent: 25,
              quantity: 1,
            },
          ],
        };

        // Trigger the one-time payment API
        await axios.post("/api/circuly/one-time-payment", paymentData);
      }

      //Add bookingTime from selectedtimeslot in calendarComp
      const bookingTime =
        data.selectedTimeslot &&
        data.selectedTimeslot.from &&
        data.selectedTimeslot.to
          ? `${data.selectedTimeslot.from}-${data.selectedTimeslot.to}`
          : "";

      const bookingData = {
        type: data.type,
        bookingDate: data.bookingDate,
        serialNumber: data.serialNumber,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        number: data.phone,
        note: data.note,
        bookingStatus: "new", // or any other status based on your logic
        addressStreet: data.address,
        addressCity: data.city,
        addressPostalCode: data.postalCode,
        deliveryTypeFromCustomer: data.deliveryType,
        subscriptionId: data.subscriptionId, // New field
        orderId: data.orderId, // New field
        winterTyres: data.winterTyres,
        bookingTime,
      };

      // Add the service booking to the operations system
      await axios.post(`/api/register-service-booking`, bookingData);

      // Update daily movement summary
      await updateDailyMovementSummary();

      // Navigate to confirmation page on success
      navigate("/booking-confirmation", { state: { bookingData: data } });
    } catch (error) {
      console.error("Error submitting data:", error);
      setErrorMsg(
        "Noe gikk galt. Vennligst ta kontakt med oss på hei@joule.no for videre hjelp. Vi beklager ulempen!"
      );
    }
    setIsLoading(false);
  };

  // Require both bookingDate and selectedTimeslot for all bookings.)
  const isSubmitDisabled = !(data.bookingDate && data.selectedTimeslot);

  const buttonClass = `buttonClass w-38 tracking-wide font-bold rounded-lg inline-flex items-center justify-center py-2 px-6 ${
    isSubmitDisabled
      ? "bg-gray-200 text-gray-400 border-gray-200 cursor-not-allowed"
      : "bg-white text-black shadow-black shadow-md hover:shadow-black hover:shadow-lg hover:scale-105"
  }`;

  return (
    <div>
      <TopBar />
      <div className="w-full h-full min-h-full flex flex-col justify-center px-4 lg:px-8 z-100 mf:h-screen">
        <h2 className="mt-6 mb-4 text-center text-lg font-century-gothic text-jouletext">
          Velg Type Service
        </h2>

        <TypeOfBooking data={data} setData={setData} />

        {data.type === "Service" && (
          <div className="flex items-center justify-center mb-4">
            <input
              type="checkbox"
              checked={data.winterTyres}
              onChange={(e) =>
                handleInputChange("winterTyres", e.target.checked)
              }
              className="mr-3 h-6 w-6 focus:ring-0"
            />
            <label className="text-white font-century-gothic text-sm">
              Jeg ønsker å bytte til sommerdekk på service
            </label>
          </div>
        )}

        <h2 className="mt-6 mb-4 text-center text-lg font-century-gothic text-jouletext">
          Velg Transportmetode
        </h2>

        <TypeOfDelivery
          data={data}
          setData={setData}
          deliveryTypeVarName="deliveryType"
          sourcePage="ServiceBookingPage"
        />

        <h2 className="mt-6 mb-4 text-center text-lg font-century-gothic text-jouletext">
          Velg Dato For Innlevering Av Sykkel
        </h2>

        <CalendarComp
          data={data}
          setData={setData}
          sourcePage="ServiceBookingPage"
        />

        <div className="flex flex-col items-center my-2">
          <h3 className="my-2 text-center text-sm font-century-gothic text-jouletext">
            Telefonnummer
          </h3>
          <input
            type="text"
            placeholder="Phone Number"
            value={data.phone}
            onChange={(e) => handleInputChange("phone", e.target.value)}
            className="my-1 text-center py-2 w-full sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-600"
          />
          {phoneError && <div className="text-red-500">{phoneError}</div>}

          {data.deliveryType === "transport" && (
            <>
              <h3 className="my-2 text-center text-sm font-century-gothic text-jouletext">
                Adresse
              </h3>
              <input
                type="text"
                placeholder="Street Address"
                value={data.address}
                onChange={(e) => handleInputChange("address", e.target.value)}
                className="my-1 text-center py-2 w-full sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-600"
              />
              <h3 className="my-2 text-center text-sm font-century-gothic text-jouletext">
                Sted
              </h3>
              <input
                type="text"
                placeholder="City"
                value={data.city}
                onChange={(e) => handleInputChange("city", e.target.value)}
                className="my-1 text-center py-2 w-full sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-600"
              />
            </>
          )}
          {data.type !== "Dekkskifte" && ( // Hide the note field if type is "Dekkskifte"
            <>
              <h3 className="mt-4 mb-2 text-center text-sm font-century-gothic text-jouletext">
                Melding til våre mekanikere
              </h3>
              <textarea
                placeholder="For å sikre best mulig service, setter vi stor pris på om du skriver ned alt du har merket – uansett om det er små ting eller større utfordringer. Alle tilbakemeldinger hjelper mekanikerne våre med å gjøre sykkelen din klar til bruk på best mulig måte."
                value={data.note}
                onChange={(e) => handleInputChange("note", e.target.value)}
                className="my-1 py-2 w-full h-40 sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-500"
              />
            </>
          )}
        </div>

        {errorMsg && <div className="text-red-400 text-center">{errorMsg}</div>}
        <div className="flex justify-center my-4 pb-8">
          <button
            onClick={handleSubmit}
            className={`${buttonClass} flex justify-center items-center`}
            disabled={isLoading || isSubmitDisabled}
          >
            {isLoading ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900 mr-2"></div>
                Laster...
              </>
            ) : (
              "Book service"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceBookingPage;
