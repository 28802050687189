import React from "react";
import InitialServiceBookingPage from "./pages/InitialServiceBookingPage";
import ServiceBookingPage from "./pages/ServiceBookingPage";
import ServiceBookingConfirmationPage from "./pages/ServiceBookingConfirmationPage";
import ServiceReturnPage from "./pages/ServiceReturnPage";
import ServiceReturnConfirmationPage from "./pages/ServiceReturnConfirmationPage";
import DeliveryPage from "./pages/DeliveryPage";
import DeliveryConfirmationPage from "./pages/DeliveryConfirmationPage";
import ReturnPage from "./pages/ReturnPage";
import ReturnConfirmationPage from "./pages/ReturnConfirmationPage";
import AvailabilityPage from "./pages/AvailabilityPage";

import axios from "axios";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
axios.defaults.withCredentials = true;

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="/service-booking/start" replace />}
        />
        <Route
          path="/service-booking/start"
          element={<InitialServiceBookingPage />}
        />
        <Route path="/service-booking" element={<ServiceBookingPage />} />
        <Route
          path="/booking-confirmation"
          element={<ServiceBookingConfirmationPage />}
        />
        <Route
          path="/service-booking/return/:bookingId"
          element={<ServiceReturnPage />}
        />
        <Route
          path="/service-booking/return-confirmation"
          element={<ServiceReturnConfirmationPage />}
        />
        <Route path="/delivery" element={<DeliveryPage />} />
        <Route
          path="/delivery-confirmation"
          element={<DeliveryConfirmationPage />}
        />
        <Route path="/return" element={<ReturnPage />} />
        <Route
          path="/return-confirmation"
          element={<ReturnConfirmationPage />}
        />
        <Route path="/availability" element={<AvailabilityPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
