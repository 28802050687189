import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "../calendar.css";
import { parseISO, format } from "date-fns"; // date formatting
import nbLocale from "date-fns/locale/nb"; // Norwegian locale
import axios from "axios";
import { validateField } from "../components/formUtils";

function CalendarComponent({
  data,
  setData,
  sourcePage,
  subscriptionActive = false, // <-- new
  existingStartDate = null, // <-- new
}) {
  // State to hold the selected date
  const [selectedDate, setSelectedDate] = useState(null);

  // State to hold the selected month for the availability data
  const [currentMonth, setCurrentMonth] = useState(new Date());

  // State to track loading status
  const [isLoading, setIsLoading] = useState(false);

  // States for availability from dailyMovements data, used to close dates, eg holidays
  const [availability, setAvailability] = useState({});

  // Transport availability from your new route
  const [transportAvailability, setTransportAvailability] = useState({});

  // Booked minutes data (used for ServiceBookingPage capacity checks)
  const [bookedMinutesPerDay, setBookedMinutesPerDay] = useState({});

  // Booked orders data (used for AvailabilityPage and DeliveryPage capacity checks)
  const [ordersCountPerDay, setOrdersCountPerDay] = useState({});

  // Timeslots for the selected date
  const [availableTimeslots, setAvailableTimeslots] = useState([]);

  // Max capacity per day in minutes for service bookings
  const MAX_MINUTES_PER_DAY = 900; // e.g. 15 hours

  //---------------------------------------------------------------------------
  // 1) Fetch availability (true/false) from dailyMovements
  //---------------------------------------------------------------------------
  useEffect(() => {
    const fetchAvailability = async () => {
      const month = currentMonth.getMonth() + 1; // JavaScript months: 0-indexed
      const year = currentMonth.getFullYear();

      try {
        const response = await axios.get("/api/dailyMovements/availability", {
          params: { month, year },
        });
        setAvailability(response.data);
      } catch (error) {
        console.error("Error fetching dailyMovements availability:", error);
        setAvailability({});
      }
    };
    fetchAvailability();
  }, [currentMonth]);

  //---------------------------------------------------------------------------
  // 2) Fetch transport availability from backend route
  //    (Only relevant when deliveryType === 'transport')
  //---------------------------------------------------------------------------
  useEffect(() => {
    const fetchTransportAvailability = async () => {
      setIsLoading(true); // Start loading

      if (!data.postalCode) {
        setTransportAvailability({});
        setAvailableTimeslots([]);
        setIsLoading(false); // Stop loading
        return;
      }

      try {
        const response = await axios.get(`/api/transport/${data.postalCode}`);
        setTransportAvailability(response.data);
      } catch (error) {
        console.error("Error fetching transport availability:", error);
        setTransportAvailability({});
      } finally {
        // Always clear out any previously loaded timeslots and stop loading
        setAvailableTimeslots([]);
        setIsLoading(false);
      }
    };

    fetchTransportAvailability();
  }, [data.postalCode, setIsLoading]);

  //---------------------------------------------------------------------------
  // 3) If this is ServiceBookingPage, also fetch bookedMinutesPerDay
  //---------------------------------------------------------------------------
  useEffect(() => {
    if (sourcePage === "ServiceBookingPage") {
      const year = currentMonth.getFullYear();
      const month = currentMonth.getMonth() + 1;

      axios
        .get("/api/bookings/daily-capacity", { params: { month, year } })
        .then((response) => {
          setBookedMinutesPerDay(response.data);
        })
        .catch((error) => {
          console.error("Error fetching aggregated bookings:", error);
          setBookedMinutesPerDay({});
        });
    } else {
      setBookedMinutesPerDay({});
    }
  }, [sourcePage, currentMonth]);

  // Function to determine booked minutes for a given date
  const calculateBookedMinutes = (date) => {
    if (sourcePage !== "ServiceBookingPage") {
      return 0;
    }

    const formattedDate = format(date, "yyyy-MM-dd");
    return bookedMinutesPerDay[formattedDate] || 0;
  };

  // Function to check if a date falls on the weekend
  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  //---------------------------------------------------------------------------
  // 4) If this is DeliveryPage og AvailabilityPage, fetch booked orders per day
  //---------------------------------------------------------------------------

  useEffect(() => {
    if (sourcePage === "DeliveryPage" || sourcePage === "AvailabilityPage") {
      const year = currentMonth.getFullYear();
      const month = currentMonth.getMonth() + 1;

      axios
        .get("/api/orders/daily-capacity", { params: { month, year } })
        .then((response) => {
          setOrdersCountPerDay(response.data); // e.g. { "2025-05-01": 15, "2025-05-02": 31, ... }
        })
        .catch((error) => {
          console.error("Error fetching orders daily capacity:", error);
          setOrdersCountPerDay({});
        });
    } else {
      setOrdersCountPerDay({});
    }
  }, [sourcePage, currentMonth]);

  //---------------------------------------------------------------------------
  // 5) Decide if a tile (date) is selectable
  //---------------------------------------------------------------------------
  const isSelectable = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // reset time

    let minSelectableDate = new Date();
    minSelectableDate.setHours(0, 0, 0, 0);

    const dateString = format(date, "yyyy-MM-dd");

    // (a) Check dailyMovements availability (used for manual closing of dates, eg. holidays or at maximum capacity)
    if (
      (sourcePage === "DeliveryPage" ||
        sourcePage === "AvailabilityPage" ||
        sourcePage === "ReturnPage" ||
        sourcePage === "ServiceBookingPage" ||
        sourcePage === "ServiceReturnPage") &&
      availability.hasOwnProperty(dateString) &&
      !availability[dateString]
    ) {
      return false;
    }

    // (b) Minimum date logic differs by sourcePage and deliveryType
    const isTransport = data.deliveryType === "transport";

    if (sourcePage === "DeliveryPage" || sourcePage === "AvailabilityPage") {
      // For these pages, we require 4 working days ahead
      let daysToAdd = 0;
      let daysCounted = 0;
      while (daysCounted < 4) {
        daysToAdd++;
        const nextDay = new Date(today);
        nextDay.setDate(today.getDate() + daysToAdd);
        if (nextDay.getDay() !== 0 && nextDay.getDay() !== 6) {
          daysCounted++;
        }
      }
      minSelectableDate.setDate(today.getDate() + daysToAdd);
    } else if (sourcePage === "ServiceReturnPage") {
      // If transport => today + 2 days, else => today
      if (isTransport) {
        minSelectableDate.setDate(today.getDate() + 2);
      } else {
        minSelectableDate = today;
      }
    } else if (sourcePage === "ReturnPage") {
      // If transport => today + 2 days, else => tomorrow
      const minDaysToAdd = isTransport ? 2 : 1;
      const calculatedMinDate = new Date(today);
      calculatedMinDate.setDate(today.getDate() + minDaysToAdd);

      // One month before subscription end date
      const subscriptionEndDate = new Date(data.subscriptionEndDate);
      const oneMonthBeforeEnd = new Date(subscriptionEndDate);
      oneMonthBeforeEnd.setMonth(subscriptionEndDate.getMonth() - 1);

      // Set minSelectableDate to the latest of (today + minDaysToAdd) and (one month before subscription end)
      minSelectableDate =
        calculatedMinDate > oneMonthBeforeEnd
          ? calculatedMinDate
          : oneMonthBeforeEnd;
    } else {
      // For all other pages (e.g., ServiceBookingPage)
      // If transport => today + 2 days, else => tomorrow
      if (isTransport) {
        minSelectableDate.setDate(today.getDate() + 2);
      } else {
        minSelectableDate.setDate(today.getDate() + 1);
      }
    }

    // Finally, block out dates before minSelectableDate
    if (date < minSelectableDate) {
      return false;
    }

    // (c) Block weekends, can be changed based on sourcepage or deliveryType at a later stage
    if (isWeekend(date)) {
      return false;
    }

    // (d) ServiceBookingPage daily capacity check
    if (sourcePage === "ServiceBookingPage") {
      const bookedMinutes = calculateBookedMinutes(date);
      if (bookedMinutes >= MAX_MINUTES_PER_DAY) {
        return false;
      }
    }

    // (e) DeliveryPage and AvailabilityPage capacity check
    if (sourcePage === "DeliveryPage" || sourcePage === "AvailabilityPage") {
      const orderCount = ordersCountPerDay[dateString] || 0;
      if (orderCount >= 30) {
        return false;
      }
    }

    // (f) Transport availability logic, different in terms of pickup/delivery depending on the sourcepage
    // If user chose "transport", we check the database's day/evening availability for a given weekday on that postal code
    if (data.deliveryType === "transport") {
      // Determine if we should use "pickup" or "delivery"
      let relevantTransportType;
      if (
        sourcePage === "DeliveryPage" ||
        sourcePage === "ServiceReturnPage" ||
        sourcePage === "AvailabilityPage"
      ) {
        // Use the 'delivery' object
        relevantTransportType = transportAvailability?.deliveryType?.delivery;
      } else if (
        sourcePage === "ReturnPage" ||
        sourcePage === "ServiceBookingPage"
      ) {
        // Use the 'pickup' object
        relevantTransportType = transportAvailability?.deliveryType?.pickup;
      }

      // If we have no relevant data at all, block all dates
      if (!relevantTransportType) {
        return false;
      }

      const dayOfWeek = format(date, "EEEE");
      const isDayAvailable =
        relevantTransportType.day?.availability.includes(dayOfWeek);
      const isEveningAvailable =
        relevantTransportType.evening?.availability.includes(dayOfWeek);

      // If neither day nor evening is available for this date, block it
      if (!isDayAvailable && !isEveningAvailable) {
        return false;
      }
    }

    return true;
  };

  //---------------------------------------------------------------------------
  // 6) Handle user selecting a date
  //---------------------------------------------------------------------------
  const handleDateChange = (date) => {
    if (!isSelectable(date)) return;

    setSelectedDate(date);
    const formattedDate = format(date, "yyyy-MM-dd");

    // Reset timeslot whenever a new date is selected
    let newState = { selectedTimeslot: "" };
    if (
      sourcePage === "DeliveryPage" ||
      sourcePage === "ReturnPage" ||
      sourcePage === "AvailabilityPage"
    ) {
      newState.deliveryDate = formattedDate;
    } else if (sourcePage === "ServiceBookingPage") {
      newState.bookingDate = formattedDate;
    } else if (sourcePage === "ServiceReturnPage") {
      newState.returnDate = formattedDate;
    }

    setData({ ...data, ...newState });

    // If user selected "transport", figure out which timeslots are available for any given weekday
    // In handleDateChange, also set selectedTimeslot automatically if only one timeslot is available
    if (data.deliveryType === "transport") {
      const dayOfWeek = format(date, "EEEE");

      let relevantTransportType;
      if (
        sourcePage === "DeliveryPage" ||
        sourcePage === "ServiceReturnPage" ||
        sourcePage === "AvailabilityPage"
      ) {
        relevantTransportType = transportAvailability?.deliveryType?.delivery;
      } else if (
        sourcePage === "ReturnPage" ||
        sourcePage === "ServiceBookingPage"
      ) {
        relevantTransportType = transportAvailability?.deliveryType?.pickup;
      }

      const timeslots = [];
      if (relevantTransportType?.day?.availability.includes(dayOfWeek)) {
        timeslots.push({
          key: "day",
          from: "10:00",
          to: "16:00",
          price: relevantTransportType.day.price,
          category: relevantTransportType.day.category, // e.g. "singeltur" or "distribusjon"
        });
      }
      if (relevantTransportType?.evening?.availability.includes(dayOfWeek)) {
        timeslots.push({
          key: "evening",
          from: "16:00",
          to: "22:00",
          price: relevantTransportType.evening.price,
          category: relevantTransportType.evening.category,
        });
      }

      // If exactly one timeslot is available, set it automatically
      if (timeslots.length === 1) {
        newState.selectedTimeslot = timeslots[0];
        setData({ ...data, ...newState });
      } else {
        setData({ ...data, ...newState });
      }

      setAvailableTimeslots(timeslots);
    } else if (data.deliveryType === "nydalen") {
      // Show two fixed timeslots for Nydalen; no auto-selection
      const timeslots = [
        { key: "slot1", from: "10:00", to: "14:00" },
        { key: "slot2", from: "14:00", to: "17:00" },
      ];

      setAvailableTimeslots(timeslots);
      setData({ ...data, ...newState });
    } else {
      setAvailableTimeslots([]);
      setData({ ...data, ...newState });
    }
  };

  //---------------------------------------------------------------------------
  // 7) Handle user changing month in the calendar
  //---------------------------------------------------------------------------
  const handleMonthChange = ({ activeStartDate }) => {
    setCurrentMonth(activeStartDate);
    setSelectedDate(null);

    // Also reset any previously chosen date/timeslot
    let newState = { selectedTimeslot: "" };
    if (
      sourcePage === "DeliveryPage" ||
      sourcePage === "ReturnPage" ||
      sourcePage === "AvailabilityPage"
    ) {
      newState.deliveryDate = "";
    } else if (sourcePage === "ServiceBookingPage") {
      newState.bookingDate = "";
    } else if (sourcePage === "ServiceReturnPage") {
      newState.returnDate = "";
    }

    setData({ ...data, ...newState });
  };

  //---------------------------------------------------------------------------
  // 8) Handle user changing deliveryType
  //---------------------------------------------------------------------------

  useEffect(() => {
    setSelectedDate(null);
    setAvailableTimeslots([]);
    setData((prev) => ({
      ...prev,
      selectedTimeslot: "",
    }));
  }, [data.deliveryType, setData]);

  //---------------------------------------------------------------------------
  // 9) Customize the calendar tiles
  //---------------------------------------------------------------------------
  const customTileClassName = ({ date, view }) => {
    if (view === "month") {
      return isSelectable(date) ? "" : "not-selectable";
    }
    return "";
  };

  // ---------------------------------------------------------------------------
  // 10) Conditional check/return is a subscription is active on the delivery of new orders
  // ---------------------------------------------------------------------------
  if (sourcePage === "DeliveryPage" && subscriptionActive) {
    // Subscription is active, so show message instead of calendar
    return (
      <div className="my-2 text-center relative bg-gray-50 p-4 rounded-lg">
        <p className="text-black font-semibold">
          Du har valgt utleveringsdato den{" "}
          {existingStartDate
            ? `${format(parseISO(existingStartDate), "d. MMMM yyyy", {
                locale: nbLocale,
              })}`
            : ""}
          .
        </p>
        <p className="text-black mt-2">
          Vi har allerede opprettet et abonnement på elsykkelen din, som
          aktiveres denne dagen.
          <br />
          Ta kontakt på{" "}
          <a
            href="mailto:hei@joule.no"
            className="underline text-blue-500 hover:text-blue-700"
          >
            hei@joule.no
          </a>{" "}
          dersom du ønsker å endre utleveringsdato 😊
        </p>
      </div>
    );
  }

  return (
    <div className="my-2 text-center relative">
      <div className="bg-white p-6 rounded-lg shadow-md mx-auto w-full sm:w-2/3 md:w-1/2 flex justify-center">
        <Calendar
          onChange={handleDateChange}
          value={selectedDate}
          selectRange={false}
          tileDisabled={({ date }) => !isSelectable(date)}
          minDetail="month"
          tileClassName={customTileClassName}
          next2Label={null}
          prev2Label={null}
          locale="nb-NO"
          onActiveStartDateChange={handleMonthChange}
        />
      </div>

      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full bg-gray-700 bg-opacity-50 flex justify-center items-center rounded-lg">
          <p className="text-white text-lg">Loading...</p>
        </div>
      )}

      {sourcePage === "AvailabilityPage" ? (
        // Always show this text for AvailabilityPage
        <p className="text-gray-300 mt-4">
          Du vil motta en mail etter bestilling der du velger dato for
          utlevering
        </p>
      ) : (
        // Else, only show "Dato er nødvendig" if validateField returns true
        validateField("selectedDate", selectedDate) && (
          <p className="text-gray-300 mt-4">Dato er nødvendig.</p>
        )
      )}

      {selectedDate && (
        <p className="mt-3 text-white text-sm">
          Valgt dato: {format(selectedDate, "PP", { locale: nbLocale })}
        </p>
      )}

      {/* Example message if user picks "today" */}
      {selectedDate &&
        selectedDate.setHours(0, 0, 0, 0) ===
          new Date().setHours(0, 0, 0, 0) && (
          <p className="mt-3 text-white text-md">
            NB: Husk at våre åpningstider på servicesenteret er mellom 10-17
          </p>
        )}

      {/* Show the available timeslots */}
      {selectedDate &&
        (data.deliveryType === "transport" ||
          data.deliveryType === "nydalen") && (
          <div className="my-6 w-full flex flex-col items-center">
            <h2 className="mt-6 mb-4 text-center text-lg font-century-gothic text-jouletext">
              Tilgjengelige tidspunkt
            </h2>
            <div className="flex space-x-4 justify-center">
              {availableTimeslots.map((slot) => (
                <button
                  key={slot.key}
                  onClick={() => setData({ ...data, selectedTimeslot: slot })}
                  className={`min-w-[120px] max-w-[160px] h-16
                      tracking-wide text-xs md:text-sm
                      rounded-lg border-black
                      hover:bg-gray-100 hover:text-black
                      py-2 px-3 md:px-6 inline-flex flex-col
                      items-center justify-center
                      ${
                        data.selectedTimeslot?.key === slot.key
                          ? "bg-white text-black shadow-black shadow-lg focus:transform focus:scale-105"
                          : "bg-jouledark text-jouletext2"
                      }`}
                >
                  <span>
                    {slot.from} - {slot.to}
                  </span>
                  {/* Only show price for transport, not nydalen */}
                  {data.deliveryType === "transport" &&
                    (sourcePage === "ReturnPage" ||
                      sourcePage === "AvailabilityPage" ||
                      sourcePage === "ServiceBookingPage" ||
                      sourcePage === "ServiceReturnPage") && (
                      <span className="text-xs italic mt-1">
                        kr {slot.price},-
                      </span>
                    )}
                </button>
              ))}
            </div>
          </div>
        )}
    </div>
  );
}

export default CalendarComponent;
