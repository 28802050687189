import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import CalendarComp from "../components/CalendarComp";
import queryString from "query-string";
import axios from "axios";

const DeliveryPage = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // --------------------------------------------------------------------------
  // 1) Utility: Normalize Phone Number
  // --------------------------------------------------------------------------
  /**
   * Normalize phone numbers to +47XXXXXXXX format where appropriate.
   */
  const normalizePhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    // Remove non-digit characters
    const cleanedNumber = phoneNumber.replace(/\D/g, "");

    // If it starts with "47" and has 10 digits
    if (cleanedNumber.startsWith("47") && cleanedNumber.length === 10) {
      return `+${cleanedNumber}`;
    }
    // If it starts with a 9 or 4 and has 8 digits
    if (
      (cleanedNumber.startsWith("9") || cleanedNumber.startsWith("4")) &&
      cleanedNumber.length === 8
    ) {
      return `+47${cleanedNumber}`;
    }

    // Otherwise, return original (it might already be in international format)
    return phoneNumber;
  };

  // --------------------------------------------------------------------------
  // 2) State object to hold form data
  // --------------------------------------------------------------------------
  const [data, setData] = useState({
    circulyOrderID: "",
    deliveryType: "",
    postalCode: "",
    deliveryDate: "",
    selectedTimeslot: "",
    phone: "",
  });

  // Track subscription check results
  const [subscriptionCheck, setSubscriptionCheck] = useState({
    subscriptionActive: false,
    orderTagDate: null,
  });

  // --------------------------------------------------------------------------
  // 3) On mount, parse query params & set initial data
  // --------------------------------------------------------------------------
  useEffect(() => {
    const queryParams = queryString.parse(search);

    setData((currentData) => ({
      ...currentData,
      circulyOrderID: queryParams.orderID || "",
      deliveryType: queryParams.shippingMethod || "",
      postalCode: queryParams.postalCode || "",
      // Remove spaces from phone here if you'd like,
      // but we can rely on normalizePhoneNumber in handleSubmit
      phone: queryParams.phone ? queryParams.phone.replace(/\s/g, "") : "",
    }));
  }, [search]);

  // --------------------------------------------------------------------------
  // 4) Check if subscription already exists for the given order
  // --------------------------------------------------------------------------
  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      if (!data.circulyOrderID) return; // No ID -> skip

      try {
        const res = await axios.get(
          `/api/orders/orderId/${data.circulyOrderID}/subscription-check`
        );

        setSubscriptionCheck(res.data);
      } catch (err) {
        console.error("Error checking subscription:", err);
      }
    };

    fetchSubscriptionStatus();
  }, [data.circulyOrderID]);

  // --------------------------------------------------------------------------
  // 5) Update daily movement summary
  // --------------------------------------------------------------------------
  const updateDailyMovementSummary = async () => {
    const requestBody = {
      bookingDate: data.deliveryDate,
      deliveryType: data.deliveryType, // "transport" or "nydalen"
      movementType: "delivery",
      activityType: "order",
    };

    try {
      await axios.post("/api/dailyMovements/update", requestBody);
      console.log("Daily Movement Summary Updated Successfully");
    } catch (error) {
      console.error("Error updating daily movement summary:", error);
    }
  };

  // --------------------------------------------------------------------------
  // 6) Submit Handler
  // --------------------------------------------------------------------------
  const handleSubmit = async () => {
    setIsLoading(true);

    // Ensure we have both date + timeslot + timeslot.from
    if (
      !data.deliveryDate ||
      !data.selectedTimeslot ||
      !data.selectedTimeslot.from
    ) {
      console.error("No delivery date or timeslot selected");
      setErrorMsg("Du må velge både dato og tidsluke.");
      setIsLoading(false);
      return;
    }

    // Build tagDate from timeslot.from
    const timeSlotStart = data.selectedTimeslot.from;
    const tagDate = `${data.deliveryDate}T${timeSlotStart}:00.000000Z`;
    console.log("Formatted tag_date:", tagDate);

    // Build the new variable deliveryTime: "from-to"
    const deliveryTime = `${data.selectedTimeslot.from}-${data.selectedTimeslot.to}`;

    try {
      // 1) Tag the order in Circuly
      await axios.post(`/api/circuly-orders/${data.circulyOrderID}/tag`, {
        tag: "in clarification",
        tag_date: tagDate,
      });

      // 2) Update daily movement summary
      await updateDailyMovementSummary();

      // 3) Optionally send SMS if phone is non-empty
      if (data.phone) {
        const normalizedPhone = normalizePhoneNumber(data.phone);

        try {
          await axios.post("/api/orders/delivery/sms", {
            phone: normalizedPhone,
            deliveryDate: data.deliveryDate,
            deliveryTime, // "from-to"
            deliveryType: data.deliveryType,
            postalCode: data.postalCode,
            orderId: data.circulyOrderID,
          });
          console.log("SMS sent successfully");
        } catch (smsErr) {
          console.error("Error sending SMS:", smsErr);
        }
      } else {
        console.log("No phone number found, skipping SMS...");
      }

      // 4) Navigate to confirmation page
      navigate("/delivery-confirmation", { state: { deliveryData: data } });
    } catch (error) {
      console.error("Error submitting data:", error);
      setErrorMsg(
        "Det oppstod dessverre en feil ved innsending av data. Send oss en mail på hei@joule.no, så hjelper vi deg med bestilling av utlevering."
      );
    }

    setIsLoading(false);
  };

  // --------------------------------------------------------------------------
  // 7) Button Disabling Logic
  // --------------------------------------------------------------------------
  const isSubmitDisabled = !(data.deliveryDate && data.selectedTimeslot);

  const buttonClass = `w-32 tracking-wide font-bold rounded-lg py-2 px-6 inline-flex items-center ${
    isSubmitDisabled
      ? "bg-gray-200 text-gray-400 border-gray-200 cursor-not-allowed"
      : "bg-white text-black shadow-black shadow-md hover:shadow-black hover:shadow-lg hover:scale-105"
  }`;

  // --------------------------------------------------------------------------
  // 8) Render
  // --------------------------------------------------------------------------
  return (
    <div>
      <TopBar />
      <div className="w-full h-full min-h-full flex flex-col justify-center px-4 lg:px-8 z-100 mf:h-screen">
        <h2 className="mt-6 mb-4 text-center text-lg font-century-gothic text-jouletext">
          Velg dato for utlevering
        </h2>

        <CalendarComp
          data={data}
          setData={setData}
          sourcePage="DeliveryPage"
          subscriptionActive={subscriptionCheck.subscriptionActive}
          existingStartDate={subscriptionCheck.orderTagDate}
        />

        {errorMsg && <div className="text-red-400 text-center">{errorMsg}</div>}

        <div className="flex justify-center my-4">
          <button
            onClick={handleSubmit}
            className={`${buttonClass} flex justify-center items-center`}
            disabled={isSubmitDisabled || isLoading}
          >
            {isLoading ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900 mr-2" />
                Laster...
              </>
            ) : (
              "Bestill utlevering"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPage;
