import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import CalendarComp from "../components/CalendarComp";
import TypeOfDelivery from "../components/TypeOfDelivery";
import queryString from "query-string";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { nb } from "date-fns/locale";
import { pickupOrder } from "../components/TransportAPI"; // Adjust path as needed

const ReturnPage = () => {
  const { search } = useLocation();
  const navigate = useNavigate(); // Instantiate useNavigate
  const [errorMsg, setErrorMsg] = useState(""); // State for holding error messages
  const [phoneError, setPhoneError] = useState(""); //State for holding phone number error
  const [isLoading, setIsLoading] = useState(false);

  //Store relevant data
  const [data, setData] = useState({
    subscriptionId: "",
    orderId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    serialNumber: "",
    address: "",
    city: "",
    postalCode: "",
    deliveryType: "nydalen", //nydalen eller transport
    deliveryDate: "",
    selectedTimeslot: "",
    subscriptionEndDate: "",
  });

  //Validation function to make sure that phone number is in the right format
  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\+\d{10}$/; // Adjust the regex based on the exact format you need
    if (!regex.test(phoneNumber)) {
      setPhoneError("Phone number must be in the format +47XXXXXXXX");
    } else {
      setPhoneError(""); // Clear error if the phone number is valid
    }
  };

  //Retrieve the subscriptionID from the URL, and then perform a get request on the ReturnData from the Operations system
  useEffect(() => {
    // Parse the URL query parameters
    const queryParams = queryString.parse(search);

    // Define the asynchronous function to fetch return and inventory information
    const fetchReturnInfo = async (subscriptionId) => {
      try {
        const returnInfoResponse = await axios.get(
          `/api/returns/subscription/${subscriptionId}`
        );
        const returnInfo = returnInfoResponse.data;

        // Merge all retrieved data in one go, preserving any existing values
        setData((prevData) => ({
          ...prevData,
          subscriptionId: returnInfo.subscriptionId ?? prevData.subscriptionId,
          orderId: returnInfo.orderId ?? prevData.orderId,
          firstName: returnInfo.firstName ?? prevData.firstName,
          lastName: returnInfo.lastName ?? prevData.lastName,
          email: returnInfo.email ?? prevData.email,
          phone: returnInfo.phone ?? prevData.phone,
          serialNumber: returnInfo.serialNumber ?? prevData.serialNumber,
          address: returnInfo.shippingStreet ?? prevData.address,
          city: returnInfo.shippingCity ?? prevData.city,
          postalCode: returnInfo.shippingPostalCode ?? prevData.postalCode,
          subscriptionEndDate:
            returnInfo.subscriptionEndDate ?? prevData.subscriptionEndDate,
        }));

        // Validate that the phone number is in the correct format if present
        if (returnInfo.phone) {
          validatePhoneNumber(returnInfo.phone);
        }
      } catch (error) {
        console.error("Error fetching return or inventory information:", error);
        setErrorMsg(
          "Failed to fetch return or inventory information. Please try again."
        );
      }
    };

    // If the subscriptionID is in the query, update state and then fetch the return info
    if (queryParams.subscriptionID) {
      setData((prevData) => ({
        ...prevData,
        subscriptionId: queryParams.subscriptionID,
      }));

      fetchReturnInfo(queryParams.subscriptionID);
    }
  }, [search]);

  // Handle input changes for data fields
  const handleInputChange = (field, value) => {
    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Effect to update deliverydate and selectedTimeslot if the postal code changes
  useEffect(() => {
    if (data.postalCode) {
      setData((prevData) => ({
        ...prevData,
        deliveryDate: "",
        selectedTimeslot: "",
      }));
    }
  }, [data.postalCode]);

  //Code that updates the count on bike movements from service in the dailymovementsummary collection
  const updateDailyMovementSummary = async () => {
    const requestBody = {
      bookingDate: data.deliveryDate,
      deliveryType: data.deliveryType, // "transport" or "nydalen"
      movementType: "pickup",
      activityType: "cancellation",
    };

    try {
      await axios.post("/api/dailyMovements/update", requestBody);
      console.log("Daily Movement Summary Updated Successfully");
    } catch (error) {
      console.error("Error updating daily movement summary:", error);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true); // Start loading before the async operation
    try {
      // If they chose transport, call pickupOrder to create the Opter order
      if (data.deliveryType === "transport") {
        await pickupOrder(data);
      }

      // If the delivery type is 'transport', make a one-time payment
      if (data.deliveryType === "transport") {
        const price = data?.selectedTimeslot?.price;
        const paymentData = {
          amount: price,
          order_id: data.orderId,
          message: `Kostnad for transport den ${data.deliveryDate} i forbindelse med avslutning av abonnement`,
          products: [
            {
              product: "Transport",
              amount: price,
              tax_percent: 25,
              quantity: 1,
            },
          ],
        };

        // Trigger the one-time payment API
        await axios.post("/api/circuly/one-time-payment", paymentData);
      }

      //Add returnTime from selectedtimeslot in calendarComp
      const returnTime =
        data.selectedTimeslot &&
        data.selectedTimeslot.from &&
        data.selectedTimeslot.to
          ? `${data.selectedTimeslot.from}-${data.selectedTimeslot.to}`
          : "";

      // Update the return instance in the operations system with return method and return date
      const returnData = {
        deliveryType: data.deliveryType,
        deliveryDate: data.deliveryDate,
        returnTime,
      };

      await axios.put(`/api/returns/update/${data.subscriptionId}`, returnData);

      // Add to movement summary
      await updateDailyMovementSummary();

      // Navigate to confirmation page on success
      navigate("/return-confirmation", { state: { returnData: data } });
    } catch (error) {
      console.error("Error submitting data:", error);
      // Set error message to display to the user
      setErrorMsg(
        "Det oppstod dessverre en feil ved innsending av data, send oss en mail på hei@joule.no, så hjelper vi deg med tilbakelevering."
      );
    }
    setIsLoading(false); // Stop loading after the async operation is complete
  };

  // Require both deliveryDate and selectedTimeslot for all returns.)
  const isSubmitDisabled = !(data.deliveryDate && data.selectedTimeslot);

  // Dynamically generating the button class abled/disabled submit button
  const buttonClass = `buttonClass w-38 tracking-wide font-bold rounded-lg inline-flex items-center justify-center py-2 px-6 ${
    isSubmitDisabled
      ? "bg-gray-200 text-gray-400 border-gray-200 cursor-not-allowed"
      : "bg-white text-black shadow-black shadow-md hover:shadow-black hover:shadow-lg hover:scale-105"
  }`;

  return (
    <div>
      <TopBar />
      <div className="w-full h-full min-h-full flex flex-col justify-center px-4 lg:px-8 z-100 mf:h-screen">
        <h2 className="mt-6 mb-4 text-center text-lg font-century-gothic text-jouletext">
          Velg metode for tilbakelevering av elsykkel
        </h2>
        <TypeOfDelivery
          data={data}
          setData={setData}
          deliveryTypeVarName="deliveryType"
          sourcePage="ReturnPage"
        />

        {data.subscriptionEndDate && (
          <div className="text-center text-jouletext my-4">
            Ditt abonnement utløper{" "}
            {format(parseISO(data.subscriptionEndDate), "d. MMMM yyyy", {
              locale: nb,
            })}
          </div>
        )}

        <CalendarComp data={data} setData={setData} sourcePage="ReturnPage" />

        {data.deliveryType === "transport" && (
          <div className="flex flex-col items-center my-2">
            <input
              type="text"
              placeholder={`Telefonnummer: ${data.phone || "Ikke oppgitt"}`}
              onChange={(e) => handleInputChange("phone", e.target.value)}
              className="my-1 text-center py-2 w-full sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-600"
            />
            {phoneError && <div className="text-red-500">{phoneError}</div>}
            <input
              type="text"
              placeholder={`Gateadresse: ${data.address || "Ikke oppgitt"}`}
              onChange={(e) => handleInputChange("address", e.target.value)}
              className="my-1 text-center py-2 w-full sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-600"
            />
            <input
              type="text"
              placeholder={`Poststed: ${data.city || "Ikke oppgitt"}`}
              onChange={(e) => handleInputChange("city", e.target.value)}
              className="my-1 text-center py-2 w-full sm:w-2/3 md:w-1/2 rounded-lg border-2 border-gray-300 placeholder:text-gray-600"
            />
          </div>
        )}

        {errorMsg && <div className="text-red-400 text-center">{errorMsg}</div>}
        <div className="flex justify-center my-4 pb-8">
          <button
            onClick={handleSubmit}
            className={`${buttonClass} flex justify-center items-center`}
            disabled={isSubmitDisabled || isLoading}
          >
            {isLoading ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900 mr-2"></div>
                Laster...
              </>
            ) : (
              "Registrer dato for retur"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReturnPage;
