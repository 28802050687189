import React, { useState } from "react";

const TypeOfBooking = ({ data, setData }) => {
  const [bookingType, setBookingType] = useState("Service");

  return (
    <div className="flex flex-wrap my-7 font-century-gothic justify-center mx-auto w-full sm:w-2/3 md:w-1/2">
      <div className="w-full sm:w-1/3 p-2">
        <button
          className={`w-full h-16 tracking-wide text-sm rounded-lg border-black hover:bg-gray-100 hover:text-black py-2 px-6 inline-flex items-center justify-center ${
            bookingType === "Service"
              ? "bg-white text-black shadow-black shadow-lg focus:transform focus:scale-105"
              : "bg-jouledark text-jouletext2"
          }`}
          onClick={() => {
            setBookingType("Service");
            setData({ ...data, type: "Service" });
          }}
        >
          <span className="mx-auto">HALVÅRLIG SERVICE</span>
        </button>
      </div>
      <div className="w-full sm:w-1/3 p-2">
        <button
          className={`w-full h-16 tracking-wide text-sm rounded-lg border-black hover:bg-gray-100 hover:text-black py-2 px-6 inline-flex items-center justify-center ${
            bookingType === "Reparasjon"
              ? "bg-white text-black shadow-black shadow-lg focus:transform focus:scale-105"
              : "bg-jouledark text-jouletext2"
          }`}
          onClick={() => {
            setBookingType("Reparasjon");
            setData({ ...data, type: "Reparasjon" });
          }}
        >
          <span className="mx-auto">REPARASJON AV SKADE</span>
        </button>
      </div>
      <div className="w-full sm:w-1/3 p-2">
        <button
          className={`w-full h-16 tracking-wide text-sm rounded-lg border-black hover:bg-gray-100 hover:text-black py-2 px-6 inline-flex items-center justify-center ${
            bookingType === "Dekkskifte"
              ? "bg-white text-black shadow-black shadow-lg focus:transform focus:scale-105"
              : "bg-jouledark text-jouletext2"
          }`}
          onClick={() => {
            setBookingType("Dekkskifte");
            setData({ ...data, type: "Dekkskifte" });
          }}
        >
          <span className="mx-auto">KUN BYTTE TIL SOMMERDEKK</span>
        </button>
      </div>
    </div>
  );
};

export default TypeOfBooking;
