import React from "react";
import { useLocation } from "react-router-dom";
import ConfirmationPageComp from "../components/ConfirmationPageComp";

const DeliveryConfirmationPage = () => {
  const location = useLocation();
  const deliveryData = location.state?.deliveryData || {};

  return <ConfirmationPageComp pageType="delivery" data={deliveryData} />;
};

export default DeliveryConfirmationPage;
