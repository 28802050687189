// TransportAPI.jsx
import axios from "axios";

/**
 * Utility function to capitalize the first letter of a string.
 */
function capitalizeFirstLetter(str) {
  if (!str || !str.length) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Build earliestTransportDateTime and latestTransportDateTime as local time strings
 */
function buildISODateTimes(bookingDate, selectedTimeslot) {
  const fromTime = selectedTimeslot?.from || "08:00";
  const toTime = selectedTimeslot?.to || "16:00";

  const earliestISO = `${bookingDate}T${fromTime}:00`;
  const latestISO = `${bookingDate}T${toTime}:00`;

  return { earliestISO, latestISO };
}

/**
 * If your timeslot has a "category" field, use it.
 * Otherwise, fallback to "Singeltur" or parse from "key"
 */
function getServiceMappingCode(timeslot) {
  if (timeslot?.category) {
    // e.g. "singeltur" => "Singeltur"
    return capitalizeFirstLetter(timeslot.category);
  }
  // fallback
  return "Singeltur";
}

/**
 * 1) DELIVERY ORDER (ServiceReturnPage)
 */
export async function deliveryOrder(data) {
  const bookingDate = data.bookingDate || data.returnDate || data.deliveryDate;
  const now = new Date().toISOString();
  const { earliestISO, latestISO } = buildISODateTimes(
    bookingDate,
    data.selectedTimeslot
  );

  const payload = {
    eventCreatedDateTime: now,
    eventSender: "Joule AS",
    eventReceiver: `${data.firstName} ${data.lastName}`,
    order: {
      messageType: "new",
      customerOrderReference: `${data.firstName} ${data.lastName}` || "",
      opterOrderId: data.serialNumber || "",
      serviceMappingCode: getServiceMappingCode(data.selectedTimeslot),
      transportDate: bookingDate,
      goodsType: "E-bike",
      officeMessage: "",
      addresses: [
        {
          addressType: "pickup",
          earliestTransportDateTime: earliestISO,
          name: "Joule AS",
          careOf: `SN${data.serialNumber}`,
          streetAddress: "Gjerdrums vei 11",
          zipCode: "0484",
          city: "Oslo",
          countryCode: "NO",
          phoneNumber: "+4741412277",
          emailAddress: "hei@joule.no",
          driverInstructions: "",
        },
        {
          addressType: "delivery",
          latestTransportDateTime: latestISO,
          name: `${data.firstName} ${data.lastName}`,
          careOf: `SN${data.serialNumber}`,
          streetAddress: data.address || "",
          zipCode: data.postalCode || "",
          city: data.city || "",
          countryCode: "NO",
          phoneNumber: data.phone || "",
          emailAddress: data.email || "",
          driverInstructions: "",
        },
      ],
      packages: [
        {
          text: "Add bike description",
          goodsType: "E-bike",
          dimensions: {
            quantity: 1,
            weightKgm: 25,
          },
        },
      ],
    },
  };

  return axios.post("/api/newOpterTransport", payload);
}

/**
 * 2) PICKUP ORDER (ServiceBookingPage, ReturnPage)
 */
export async function pickupOrder(data) {
  const bookingDate = data.bookingDate || data.returnDate || data.deliveryDate;
  const now = new Date().toISOString();
  const { earliestISO, latestISO } = buildISODateTimes(
    bookingDate,
    data.selectedTimeslot
  );

  const payload = {
    eventCreatedDateTime: now,
    eventSender: `${data.firstName} ${data.lastName}`,
    eventReceiver: "Joule AS",
    order: {
      messageType: "new",
      customerOrderReference: `${data.firstName} ${data.lastName}` || "",
      opterOrderId: data.serialNumber || "",
      serviceMappingCode: getServiceMappingCode(data.selectedTimeslot),
      transportDate: bookingDate,
      goodsType: "E-bike",
      officeMessage: "",
      addresses: [
        {
          addressType: "pickup",
          earliestTransportDateTime: earliestISO,
          name: `${data.firstName} ${data.lastName}`,
          careOf: `SN${data.serialNumber}`,
          streetAddress: data.address || "",
          zipCode: data.postalCode || "",
          city: data.city || "",
          countryCode: "NO",
          phoneNumber: data.phone || "",
          emailAddress: data.email || "",
          driverInstructions: "",
        },
        {
          addressType: "delivery",
          latestTransportDateTime: latestISO,
          name: "Joule AS",
          careOf: `SN${data.serialNumber}`,
          streetAddress: "Gjerdrums vei 11",
          zipCode: "0484",
          city: "Oslo",
          countryCode: "NO",
          phoneNumber: "+4741412277",
          emailAddress: "hei@joule.no",
          driverInstructions: "",
        },
      ],
      packages: [
        {
          text: "Add bike description",
          goodsType: "E-bike",
          dimensions: {
            quantity: 1,
            weightKgm: 25,
          },
        },
      ],
    },
  };

  return axios.post("/api/newOpterTransport", payload);
}
